import React, { useState } from "react";
import Papa from "papaparse";
import { DataGrid } from '@mui/x-data-grid';

const CSVReader = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    const handleFileUpload = (e) => {
        Papa.parse(e.target.files[0], {
            dynamicTyping: true,
            header: true,
            complete: function (results) {
                const cols = results.meta.fields.map((field, id) => ({
                    field: field,
                    headerName: field,
                    width: 200,
                    id: id,
                }));

                setData(() => {
                    return results.data.map((row, id) => ({ id: id, ...row }));
                });
                setColumns(cols);
            }
        });
    }

    return (
        <div>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            {data.length > 0 &&
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid rows={data} columns={columns} pageSize={5} />
                </div>
            }
        </div>
    );
}

export default CSVReader;