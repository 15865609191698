const contentData = [
  {
    title: "Dext Prepare",
    link: "https://dext.com/ca/products/prepare",
    description:
      "Dext Prepare collects and extracts your paperwork with 99% accuracy, then automatically processes it to your accountancy software. Giving you more time to focus on work that adds value."
  },
  {
    title: "Dext Commerce",
    link: "https://dext.com/ca/products/commerce",
    description:
      "Created for bookkeepers, accountants and businesses, Dext Commerce's accounting automation software provides the data, insight and tools for companies to thrive."
  },
  {
    title: "Dext Precision",
    link: "https://dext.com/ca/products/precision",
    description:
      "The advice you give clients is only as good as the data you’re working with. As well as alerting you to data that’s missing or simply bad, Dext Precision provides you with proactive insights, all of which mean your team can offer greater value to clients."
  },
  {
    title: "For Businesses",
    link: "https://dext.com/ca/business-type/business-owners",
    description:
      "Searching for a solution that helps you save time on admin, guarantees data accuracy and gives you an instant insight into your business financials? Dext is the platform for you!."
  }
];

export default contentData;
