import React from "react";
import logo from "../assets/logo.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";


const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="Dext logo" width="120" />
    <h1 className="mb-4">Test SSO APP</h1>

    <p className="lead">
      This is a sample application to test authentication and SSO with WorkRamp.
    </p>

    <div className="button-container mb-4">
      <Button
        color="info"
        className="btn-margin m-2"
        onClick={() => window.open("https://dext-test.workramp.io/trainings", "_blank")}
      >
        Access WorkRamp Trainings
      </Button>

      <Button
        color="info"
        className="btn-margin m-2"
        onClick={() => window.open("https://dext-test.workramp.io/login", "_blank")}
      >
        Access Login
      </Button>

      <Link to="/csvreader">
        <Button
          color="info"
          className="btn-margin m-2"
        >
          Go to CSV Reader
        </Button>
      </Link>
    </div>
  </div>
);

export default Hero;
