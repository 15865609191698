import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import CSVReader from './components/CSVReader';
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const ProtectedRoute = ({ component: Component, ...args }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <Route
      {...args}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          loginWithRedirect();
          return <Loading />;
        }
      }}
    />
  );
};

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <ProtectedRoute path="/" exact component={Home} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/csvreader" component={CSVReader} />
            <Route render={() => <Redirect to="/" />} /> 
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
